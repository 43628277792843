<template>
  <div class="myco-app">
    <!-- <div class="myco_btns">
      <div
        class="btn"
        :class="type == '2' ? 'btnTwo' : ''"
        @click="qhType('1')"
      >
        知识服务
      </div>
      <div
        class="btn"
        :class="type == '1' ? 'btnTwo' : ''"
        @click="qhType('2')"
      >
        知识加工
      </div>
    </div> -->

    <template v-if="type == '1'">
      <div class="myco_text" v-if="contractData">
        <div class="text1">
          <!-- 知识服务合约 -->
          {{ contractData.contractTitle }}
        </div>
        <p class="text2">1.合约基础条款：</p>
        <p class="text3">
          同的条款是合同中经双方当事人协商一致、规定双方当事人权利义务的具体条文。合同的条即合同的内容。
          合同的权利义务，除法律规定的以外，主要由合同的条款确定。合同的条款是否齐备、准确，决定
          了合同能否成立、 生效以及能否顺利地履行、实现订立合同的目的。
          <img
            v-show="isExpire == '3'"
            class="text_img"
            src="@/assets/img/efficacy.png"
          />
        </p>
        <p class="text2">2.合约基本规则：</p>
        <p class="text3">
          永续合约与传统的期货合约相似,是传统期货合约的一个衍生产品。无论是熊市或者牛市,
          用户都可以在永续合约中通过做多或做空来实现盈利。用户需要自己指定下单的价格和数量。
          限价委托规定了用户愿意买 的最高价格或愿意卖的最低价格。
        </p>
        <p class="text2" style="color: red">
          签约时间：{{ YearMMdd(contractData.contractTime) }}
        </p>
        <p class="text2" style="color: red">
          到期时间：
          {{
            isExpire == "2"
              ? "已到期"
              : isExpire == "3"
              ? "已失效"
              : YearMMdd(contractData.endTime)
          }}
        </p>
        <div class="text_flex">
          <span class="text1"> 甲方：智荟网平台 </span>
          <span class="text1"> 乙方：{{ contractData.vendorName }} </span>
        </div>
      </div>
      <div v-else class="myco_text1">您还没有签约</div>
      <div class="myco_btns" style="border-width: 0" v-if="contractData">
        <div v-if="isExpire != '3'" class="btn" @click="tapContract(true)">
          续约
        </div>
        <div
          v-if="isExpire != '3'"
          class="btn btnThree"
          @click="tapContract(false)"
        >
          违约
        </div>
        <div v-else class="btn" @click="tapAnew">重新签约</div>
      </div>
      <div class="myco_btns" style="border-width: 0" v-else>
        <div class="btn" @click="tapAnew">去签约</div>
      </div>
    </template>

    <template v-else>
      <div class="zsjgList">
        <p class="zsjg_text1">任务名称</p>
        <p class="zsjg_text2">全约名称</p>
        <p class="zsjg_text1">合约类型</p>
        <!-- <p class="zsjg_text1">合约状态</p> -->
      </div>
      <div
        class="zsjgList tapColor"
        v-for="(item, i) of processData"
        :key="i"
        @click="selectNum == i ? (selectNum = -1) : (selectNum = i)"
      >
        <p class="zsjg_text1">{{ item.taskName || "之前的数据没有名称" }}</p>
        <p class="zsjg_text2 text_color">
          《{{ item.taskName }}-知识加工合约》
        </p>
        <p class="zsjg_text1">{{ clhyClass(item) }}</p>
      </div>
	  <p style="text-align:center;padding-top:20px;">暂无合约</p>
      <div class="zsjgList pageList" v-if="processData.length>0">
        <el-pagination
          background
          :page-size="limit"
          layout="prev, pager, next"
          :total="count"
          @current-change="getPageUpdate"
        >
        </el-pagination>
      </div>
      <div class="myco_text" v-if="selectNum > -1">
        <div class="text1" style="padding-bottom: 40px">
          {{ processData[selectNum].taskName }}-知识加工合约
        </div>
        <div>
          <div v-html="processData[selectNum].content"></div>
        </div>
        <p class="text2" style="color: red">
          签约时间：{{ YearMMdd(processData[selectNum].startTime) }}
        </p>
        <p class="text2" style="color: red">
          到期时间：
          {{
            processData[selectNum].endTime
              ? YearMMdd(processData[selectNum].endTime)
              : "合约未开始"
          }}
        </p>
        <div class="text_flex">
          <span class="text1"> 甲方：{{ processData[selectNum].aName }} </span>
          <span class="text1"> 乙方：{{ processData[selectNum].bName }} </span>
        </div>
      </div>
    </template>

    <div class="myco_popup" v-show="isShowTc">
      <div class="popup_main">
        <div style="height: 28px">
          <div class="main_back" @click="isShowTc = false">
            <i class="el-icon-close" style="font-size: 20px"></i>
          </div>
        </div>
        <p class="main_text">
          {{ isContract ? "确认续约" : "取消合约" }}
        </p>
        <div class="main_contract" v-if="isContract">
          <div class="sel_time">
            <span class="sel_text">请选择续约时长：</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in timeList"
                :key="item.value"
                :label="item.title"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <p class="main_ts">温馨提示：续约不再需要缴纳保证金</p>
        </div>
        <p class="main_text1" v-else>
          取消合约平台将退还所缴纳的保证金30知识币，但是上传的数据库将会从平台下架。如果再次上传数据库，需要重新签约。
        </p>
        <div class="myco_btns" style="border-width: 0">
          <div class="btn btnTwo" @click="isShowTc = false">取消</div>
          <div class="btn" style="background-color: #0084ff" @click="tapAffirm">
            确认
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { INSERT, UPDATE, QUERYED } from "@/services/dao.js";
// import {formatZero,getTimeFormat} from "@/js/common/Resoures";
import { post } from "@/services/http.js";
export default {
  data() {
    return {
      type: "2", // 1：知识服务  2：知识加工
      isShowTc: false, //是否显示弹窗
      isContract: true, //是否续约
      isExpire: "1", // 1:未到期   2:已到期 7天内   '3':已失效
      contractData: null, //知识服务合约数据
      timeList: [
        //续约时间列表
        { title: "1年", value: 1 },
        { title: "2年", value: 2 },
        { title: "3年", value: 3 },
        { title: "4年", value: 4 },
        { title: "5年", value: 5 },
        { title: "6年", value: 6 },
        { title: "7年", value: 7 },
        { title: "8年", value: 8 },
        { title: "9年", value: 9 },
        { title: "10年", value: 10 },
      ],
      value: "",

      processData: [], //加工合约列表
      offset: 0, //当前页
      limit: 5, //每页条数
      count: 0, //总条数
      selectNum: -1, //当前选择的
    };
  },
  created() {
    this.getData();
  },
  watch: {
    selectNum(val) {
      console.log(val);
    },
  },
  methods: {
    //唤起弹窗
    tapContract(boo) {
      this.isShowTc = true;
      this.isContract = boo;
    },

    //切换合约类型
    qhType(type) {
      this.type = type;
      this.getData();
    },

    //合约类型
    clhyClass(item) {
      let { aId, type } = item;
      let id = this.$store.state.userinfo.id;
      switch (type) {
        case 1:
          return "发布合约";
        case 2:
          if (id == aId) return "发布合约";
          else return "我负责的";
        case 3:
          if (id == aId) return "我负责的";
          else return "加工合约";
        default:
          return "状态未知";
      }
    },

    //获取分页
    getPageUpdate(e) {
      this.offset = e;
      this.selectNum = -1;
      this.getData();
    },

    //获取合约数据
    async getData() {
      let id = this.$store.state.userinfo.id;
      let querySql =
        'contract(where: {uid: {_eq: "' +
        id +
        '"}}) {id contractTitle contractContent contractTime' +
        " effectiveYear divided price vendorName vendorPhone ftpAddress endTime contractType}";

      if (this.type == "2") {
        querySql =
          "jgContract(where:{_or:[{aId:{_eq:" +
          id +
          "}},{bId:{_eq:" +
          id +
          "}}]},offset:" +
          this.offset +
          ",limit:" +
          this.limit +
          ") {id type aId aName bId bName content startTime endTime taskId taskName}" +
          " jgContract_aggregate(where:{_or:[{aId:{_eq:" +
          id +
          "}},{bId:{_eq:" +
          id +
          "}}]}) {aggregate {count}}";
      }

      let totals = await QUERYED("post", "", querySql);
      if (this.type == "1") {
        this.contractData = totals.data.contract[0];
      } else {
        this.processData = totals.data.jgContract;
        this.count = totals.data.jgContract_aggregate.aggregate.count;
      }
      this.contractType();
    },

    //加工合约状态
    clhyStart(item) {
      let endTime = item.endTime;
      let date = new Date() * 1;
      if (!endTime) return false;
      if (date >= endTime * 1) return true;
      if (date < endTime * 1) return false;
    },

    //时间格式转换(时间戳)(年月日)
    YearMMdd(value) {
      let date = new Date(value * 1);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;

      // let h = date.getHours();
      // h = h < 10 ? "0" + h : h;
      // let m = date.getMinutes();
      // m = m < 10 ? "0" + m : m;
      // let s = date.getSeconds();
      // s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d;
    },

    //确认续约
    async tapAffirm() {
      let { isContract, value, contractData } = this,
        num = 31536000000;
      if (isContract) {
        //续约操作
        if (!value) {
          this.$message({ message: "请选择续约时间", type: "warning" });
          return;
        }
        let time = num * value + contractData.endTime * 1;
        let totals = await UPDATE(
          "post",
          "",
          `update_contract(where: {uid: {_eq: "${this.$store.state.userinfo.id}"}}, 
						_set: {endTime: ${time}}) {   affected_rows  }`
        );
        if (totals.data.update_contract.affected_rows == "1") {
          this.isShowTc = false;
          this.value = "";
          this.$message({ message: "续约成功", type: "success" });
          this.getData();
        }
      } else {
        //违约操作
        this.deleteData();
      }
    },

    //请求后端删除数据库
    deleteData() {
      let { loginName, ftppath } = this.$store.state.userinfo;
      let fromData = new FormData();
      fromData.append("loginName", loginName);
      fromData.append("rootpath", ftppath);
      post("/common/breakContract", fromData).then((res) => {
        this.cancelContract();
      });
    },

    //取消合约
    async cancelContract() {
      let time = this.contractData.endTime * 1 - 604800000;
      let totals = await UPDATE(
        "post",
        "",
        `update_contract(where: {uid: {_eq: "${this.$store.state.userinfo.id}"}},_set: {endTime: ${time},contractType:"1"}) {affected_rows}`
      );
      if (totals.data.update_contract.affected_rows == "1") {
        this.isShowTc = false;
        this.value = "";
        this.getData();
        this.returnMoney();
      }
    },

    //返还知识币
    async returnMoney() {
      //查询用户剩余知识币
      let totals = await QUERYED(
        "post",
        "",
        `User(where: {id: {_eq: "${this.$store.state.userinfo.id}"}}) {Money}`
      );
      let money = totals.data.User[0].Money * 1 + 30;
      //返还知识币
      await UPDATE(
        "post",
        "",
        `update_User(where: {id: {_eq: "${this.$store.state.userinfo.id}"}},_set: {Money: ${money}}) {affected_rows}`
      );

      //新增一条订单
      await INSERT(
        "post",
        "",
        'insert_bill(objects: {type: 2, state: 0, time: "' +
          new Date() +
          '", userid: ' +
          this.$store.state.userinfo.id +
          ', orderNum: "' +
          // Bringbill() +
          orderNum +
          '", orderid: ' +
          1 +
          ', Price: "30", evaluate: "",classification:0}) {affected_rows    returning {    id  }  }'
      );
      this.$message({ message: "取消合约成功", type: "success" });
    },

    //合约状态
    contractType() {
      let num = 604800000;
      let date = new Date() * 1;
      if (!this.contractData) return;
      let { endTime, contractType } = this.contractData;
      if (contractType == "1") {
        //已失效
        this.isExpire = "3";
        return;
      }
      if (endTime > date) {
        //未到期
        this.isExpire = "1";
        return;
      }
      if (endTime <= date && data <= endTime + num) this.isExpire = "2";
      //已到期 7天内
      else this.isExpire = "3"; //已失效
    },

    //重新签约
    tapAnew() {},
  },
};
</script>
<style scoped>
.myco-app {
  width: 100%;
}
.myco_btns {
  width: 100%;
  height: 130px;
  border-bottom: 1px solid #a0a0a0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.btn {
  width: 154px;
  height: 52px;
  background: #0084ff;
  border-radius: 5px;
  font-size: 20px;
  color: #eeeeee;
  text-align: center;
  line-height: 50px;
}
.btn:hover {
  opacity: 0.9;
  cursor: pointer;
}
.btnTwo {
  background-color: #fff;
  border: 1px solid #666;
  color: #333;
}
.btnTwo:hover {
  background-color: rgb(236, 245, 255);
}
.myco_text {
  width: 100%;
  padding: 0 50px;
  background-color: #f6f6f6;
  box-sizing: border-box;
  border-bottom: 1px solid #a0a0a0;
}
.myco_text1 {
  padding: 30px 0;
  text-align: center;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}
.text1 {
  padding: 70px 0;
  text-align: center;
  font-size: 20px;
  color: #333;
  font-weight: bold;
}
.text2 {
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
.text3 {
  font-size: 14px;
  color: #333;
  text-indent: 28px;
  position: relative;
}
.text_img {
  width: 150px;
  position: absolute;
  right: -30px;
  top: -53px;
}
.text_flex {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.btnThree {
  background-color: rgba(201, 5, 2, 0.3);
  color: #c90502;
}
.myco_popup {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup_main {
  width: 800px;
  height: 521px;
  background-color: #fff;
}
.main_back {
  width: 28px;
  height: 28px;
  margin: 5px 5px 0 0;
  border: 1px solid #333;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.main_text {
  margin-top: 50px;
  font-size: 30px;
  color: #333;
  text-align: center;
}
.main_text1 {
  padding: 50px 90px;
  font-size: 18px;
  font-weight: normal;
  color: #333;
  text-indent: 36px;
}
.main_contract {
  padding: 50px 150px;
}
.sel_time {
  display: flex;
  align-items: center;
}
.sel_text {
  font-size: 16px;
  color: rgb(128, 119, 119);
}
.sel_input {
  width: 254px;
  height: 36px;
  border: 1px solid #000000;
}
.main_ts {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}
.zsjgList {
  padding: 0 100px;
  border-bottom: 1px solid #a0a0a0;
  display: flex;
}
.zsjg_text1,
.zsjg_text2 {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
}
.zsjg_text2 {
  flex: 1;
  padding: 0 20px;
  text-align: center;
}
.text_color {
  color: #0084ff;
  cursor: pointer;
}
.tapColor:hover {
  background-color: #eee;
}
.pageList {
  padding: 10px 0;
  justify-content: center;
}
</style>